export const logo = [
  '513.28 313.3',
  `
  <title>SDA</title>
  <g id="Camada_1-2" data-name="Camada 1">
    <path class="cls-1" d="M183.93,72.94v-39.95h22.33v4.42h-17.29v12.48h14.99v4.36h-14.99v14.27h18.52l-1.68,4.42M203.46,20.68c-1.4,2.07-5.37,6.38-8,8.39h-3.25v-.67c1.62-2.07,4.03-5.76,5.32-8.28h5.93v.56Z"/>
    <path class="cls-1" d="M209.61,78.03c0-3.86,2.18-6.32,6.55-7.39-1.73-.84-2.63-2.29-2.63-4.2,0-2.07,1.23-3.8,3.64-5.26-3.41-1.4-5.2-4.98-5.2-8.45,0-6.99,4.36-10.35,11.19-10.35,1.57,0,2.91.17,4.09.56h10.35l-1.46,3.81h-3.92c1.17,1.57,1.79,3.52,1.79,5.82,0,6.21-4.53,9.62-11.02,9.62-1.01,0-1.79-.06-2.35-.17-1.85,1.01-2.74,2.24-2.74,3.64,0,1.51,1.51,2.29,4.42,2.29h5.09c6.04,0,9.79,2.74,9.79,8.11,0,6.88-5.15,10.29-15.44,10.29-6.99,0-12.14-2.41-12.14-8.34M232.5,76.52c0-1.62-.45-2.69-1.34-3.25-.95-.56-2.74-.84-5.43-.84h-5.15c-4.2,0-6.27,1.79-6.27,5.43,0,3.13,2.46,4.76,7.39,4.76,7.22,0,10.8-2.01,10.8-6.1M229.14,52.52c0-4.37-2.07-6.55-6.15-6.55s-6.16,2.24-6.16,6.6,2.07,6.21,6.21,6.21,6.1-2.13,6.1-6.27"/>
    <polygon class="cls-1" points="242.34 72.94 242.34 32.32 247.27 30.42 247.27 72.94 242.34 72.94"/>
    <path class="cls-1" d="M261.03,34.84c0,2.12-1.34,3.13-2.85,3.13-1.62,0-2.91-1.01-2.91-3.13s1.29-3.13,2.91-3.13c1.51,0,2.85.95,2.85,3.13M260.58,72.94h-4.92v-29.99h4.92v29.99Z"/>
    <path class="cls-1" d="M276.53,73.5c-4.2,0-7.11-.67-9.4-1.9l1.45-3.81c1.96.84,5.2,1.74,8.06,1.74,4.98,0,7.11-1.74,7.11-4.42,0-2.24-1.51-3.58-7.39-5.82-5.82-2.24-9.29-4.03-9.29-8.95s4.53-7.94,11.25-7.94c3.64,0,6.77.78,9.57,2.01l-1.51,3.97c-2.57-1.12-5.48-1.96-8.39-1.96-4.03,0-6.21,1.4-6.21,3.64,0,2.52,1.96,3.53,7.72,5.71,5.71,2.12,9.01,4.08,9.01,8.9,0,5.82-4.64,8.84-11.97,8.84"/>
    <path class="cls-1" d="M318.43,55.93v2.96h-20.53c.17,6.77,3.58,10.41,9.57,10.41,2.91,0,5.88-.56,8.23-1.45l1.4,3.69c-3.08,1.34-5.76,1.96-9.85,1.96-8.56,0-14.44-5.32-14.44-15.33s5.32-15.78,13.26-15.78,12.37,5.37,12.37,13.54M298.01,54.98h15.27c-.06-5.09-2.29-8.56-7.27-8.56-4.7,0-7.5,3.19-8,8.56"/>
    <path class="cls-1" d="M359.28,60.58h-15.83l-4.76,12.37h-5.09l15.61-40.12h4.53l15.55,40.12h-5.2M353.29,44.01c-.34-.89-1.51-4.59-1.9-5.99-.56,2.29-1.29,4.7-1.74,5.99l-4.53,12.09h12.65l-4.48-12.09Z"/>
    <path class="cls-1" d="M371.86,58c0-10.29,4.92-15.61,12.37-15.61,4.64,0,7.44,1.96,9.29,4.42h.34c-.11-.95-.34-3.3-.34-4.42v-10.07l4.92-1.9v42.52h-3.97l-.73-4.03h-.22c-1.79,2.57-4.64,4.59-9.34,4.59-7.44,0-12.31-5.2-12.31-15.5M393.57,58.95v-.9c0-7.44-2.01-11.58-8.67-11.58-5.32,0-7.95,4.53-7.95,11.64s2.63,11.3,8,11.3c6.32,0,8.62-3.47,8.62-10.46"/>
    <path class="cls-1" d="M402.63,42.95h5.26l6.38,17.68c.9,2.46,2.07,6.1,2.41,7.95h.22c.39-1.85,1.68-5.54,2.52-7.95l6.38-17.68h5.26l-11.42,29.99h-5.65"/>
    <path class="cls-1" d="M458.64,55.93v2.96h-20.54c.17,6.77,3.58,10.41,9.57,10.41,2.91,0,5.88-.56,8.22-1.45l1.4,3.69c-3.08,1.34-5.76,1.96-9.85,1.96-8.56,0-14.44-5.32-14.44-15.33s5.32-15.78,13.26-15.78,12.37,5.37,12.37,13.54M438.22,54.98h15.28c-.06-5.09-2.29-8.56-7.27-8.56-4.7,0-7.5,3.19-8,8.56"/>
    <path class="cls-1" d="M490.42,53.41v19.53h-4.87v-19.19c0-4.81-2.13-7.22-6.71-7.22-6.66,0-8.79,3.86-8.79,10.85v15.56h-4.92v-29.99h3.97l.73,4.08h.28c1.96-3.13,5.65-4.64,9.46-4.64,7.16,0,10.85,3.3,10.85,11.02"/>
    <path class="cls-1" d="M510.45,68.75l1.34,3.53c-1.06.67-3.19,1.23-5.48,1.23-4.7,0-8.78-2.01-8.78-9.29v-26.3l4.92-1.85v6.88h8.67v3.8h-8.67v17.35c0,3.64,1.96,5.37,4.7,5.37,1.12,0,2.63-.39,3.3-.73"/>
    <path class="cls-1" d="M521.58,34.84c0,2.12-1.34,3.13-2.85,3.13-1.62,0-2.91-1.01-2.91-3.13s1.29-3.13,2.91-3.13c1.51,0,2.85.95,2.85,3.13M521.13,72.94h-4.92v-29.99h4.92v29.99Z"/>
    <path class="cls-1" d="M537.08,73.5c-4.2,0-7.1-.67-9.4-1.9l1.45-3.81c1.96.84,5.2,1.74,8.06,1.74,4.98,0,7.11-1.74,7.11-4.42,0-2.24-1.51-3.58-7.39-5.82-5.82-2.24-9.29-4.03-9.29-8.95s4.53-7.94,11.25-7.94c3.64,0,6.77.78,9.57,2.01l-1.51,3.97c-2.58-1.12-5.48-1.96-8.39-1.96-4.03,0-6.21,1.4-6.21,3.64,0,2.52,1.96,3.53,7.72,5.71,5.71,2.12,9.01,4.08,9.01,8.9,0,5.82-4.65,8.84-11.98,8.84"/>
    <path class="cls-1" d="M566.9,68.75l1.34,3.53c-1.06.67-3.19,1.23-5.48,1.23-4.7,0-8.78-2.01-8.78-9.29v-26.3l4.92-1.85v6.88h8.67v3.8h-8.67v17.35c0,3.64,1.96,5.37,4.7,5.37,1.12,0,2.63-.39,3.3-.73"/>
    <path class="cls-1" d="M596.61,55.93v2.96h-20.54c.17,6.77,3.58,10.41,9.57,10.41,2.91,0,5.88-.56,8.23-1.45l1.4,3.69c-3.08,1.34-5.76,1.96-9.85,1.96-8.56,0-14.44-5.32-14.44-15.33s5.32-15.78,13.26-15.78,12.36,5.37,12.36,13.54M576.19,54.98h15.28c-.05-5.09-2.29-8.56-7.27-8.56-4.7,0-7.5,3.19-8,8.56"/>
    <path class="cls-1" d="M600.58,33.16h-1.29v-.67h3.41v.67h-1.34v3.75h-.78M603.16,32.49h1.12l1.29,3.47,1.29-3.47h1.12v4.42h-.78v-3.41h-.05l-1.23,3.41h-.67l-1.23-3.41h-.06v3.41h-.78v-4.42Z"/>
    <path class="cls-1" d="M182.14,117.98c0-10.3,4.92-15.61,12.37-15.61,4.64,0,7.44,1.96,9.29,4.42h.34c-.11-.95-.34-3.3-.34-4.42v-10.07l4.92-1.9v42.52h-3.97l-.73-4.03h-.22c-1.79,2.57-4.64,4.59-9.34,4.59-7.44,0-12.31-5.2-12.31-15.5M203.85,118.93v-.9c0-7.44-2.02-11.58-8.67-11.58-5.31,0-7.94,4.53-7.94,11.64s2.63,11.3,8,11.3c6.32,0,8.62-3.47,8.62-10.46"/>
    <path class="cls-1" d="M242.74,132.92h-4.03l-.73-3.97h-.22c-1.96,3.13-5.65,4.53-9.51,4.53-7.22,0-10.91-3.3-10.91-10.91v-19.64h4.98v19.3c0,4.81,2.13,7.16,6.66,7.16,6.66,0,8.84-3.86,8.84-10.86v-15.61h4.92"/>
    <path class="cls-1" d="M274.18,133.48c-4.48,0-8.45-.67-10.97-1.9l1.57-4.14c1.73.78,5.37,1.68,9.62,1.68,5.93,0,9.01-2.46,9.01-6.44s-2.18-5.6-9.4-8.22c-6.83-2.46-10.29-5.65-10.29-11.86s5.2-10.18,12.76-10.18c4.36,0,8,.9,11.02,2.24l-1.62,4.31c-2.8-1.18-6.04-2.13-9.51-2.13-4.98,0-7.55,2.35-7.55,5.82,0,4.03,2.24,5.65,8.73,8.06,7.05,2.63,10.91,5.26,10.91,11.53,0,7.11-5.54,11.25-14.27,11.25"/>
    <path class="cls-1" d="M319.22,115.91v2.96h-20.54c.17,6.77,3.58,10.41,9.57,10.41,2.91,0,5.88-.56,8.23-1.45l1.4,3.69c-3.08,1.34-5.76,1.96-9.85,1.96-8.56,0-14.44-5.32-14.44-15.33s5.32-15.78,13.26-15.78,12.37,5.37,12.37,13.54M298.79,114.96h15.27c-.06-5.09-2.29-8.56-7.27-8.56-4.7,0-7.5,3.19-8,8.56"/>
    <path class="cls-1" d="M352.28,117.87c0,10.24-4.92,15.61-12.37,15.61-4.64,0-7.55-2.01-9.29-4.36h-.34c.11,1.29.34,3.53.34,4.92v12.31h-4.92v-43.42h4.03l.67,4.09h.22c1.79-2.57,4.48-4.65,9.34-4.65,7.39,0,12.31,5.15,12.31,15.5M330.63,116.92v.95c0,7.39,2.01,11.53,8.56,11.53,5.43,0,8-4.87,8-11.58s-2.57-11.3-8.11-11.3c-6.1,0-8.34,3.41-8.45,10.41"/>
    <path class="cls-1" d="M370.86,128.72l1.34,3.52c-1.06.67-3.19,1.23-5.48,1.23-4.7,0-8.78-2.02-8.78-9.29v-26.3l4.92-1.85v6.88h8.67v3.81h-8.67v17.34c0,3.64,1.96,5.37,4.7,5.37,1.12,0,2.63-.39,3.3-.73"/>
    <path class="cls-1" d="M381.99,94.82c0,2.13-1.34,3.13-2.85,3.13-1.62,0-2.91-1.01-2.91-3.13s1.29-3.13,2.91-3.13c1.51,0,2.85.95,2.85,3.13M381.54,132.92h-4.92v-29.99h4.92v29.99Z"/>
    <path class="cls-1" d="M413.88,115.91v2.97h-20.54c.17,6.77,3.58,10.41,9.57,10.41,2.91,0,5.88-.56,8.22-1.46l1.4,3.69c-3.08,1.34-5.76,1.96-9.85,1.96-8.56,0-14.44-5.32-14.44-15.33s5.32-15.78,13.26-15.78,12.37,5.37,12.37,13.54M393.46,114.96h15.28c-.06-5.09-2.29-8.56-7.27-8.56-4.7,0-7.5,3.19-8,8.56M405.04,98.34v.67h-3.3c-2.57-2.01-6.66-6.32-7.95-8.39v-.56h5.88c1.23,2.52,3.64,6.21,5.37,8.28"/>
    <path class="cls-1" d="M463.4,113.39v19.53h-4.87v-19.3c0-4.7-1.96-7.11-6.1-7.11-5.82,0-8.11,3.47-8.11,9.85v16.56h-4.87v-19.3c0-4.7-1.96-7.11-6.15-7.11-6.04,0-8,3.86-8,10.85v15.56h-4.92v-29.99h3.97l.73,4.09h.28c1.85-3.13,5.26-4.64,8.84-4.64,4.59,0,7.72,1.62,9.18,5.04h.28c2.01-3.41,5.71-5.04,9.62-5.04,6.77,0,10.13,3.3,10.13,11.02"/>
    <path class="cls-1" d="M495.51,115.91v2.96h-20.54c.17,6.77,3.58,10.41,9.57,10.41,2.91,0,5.88-.56,8.23-1.45l1.4,3.69c-3.08,1.34-5.76,1.96-9.85,1.96-8.56,0-14.44-5.32-14.44-15.33s5.32-15.78,13.26-15.78,12.37,5.37,12.37,13.54M475.09,114.96h15.27c-.06-5.09-2.3-8.56-7.27-8.56-4.7,0-7.5,3.19-8,8.56"/>
    <path class="cls-1" d="M511.79,139.24c2.35-1.12,4.03-3.02,4.03-8.45v-37.83h5.04v37.83c0,6.99-2.35,10.35-7.44,12.76"/>
    <path class="cls-1" d="M541.67,133.48c-7.95,0-13.77-5.65-13.77-15.61s5.32-15.5,13.93-15.5c8.11,0,13.82,5.59,13.82,15.5s-5.43,15.61-13.99,15.61M541.78,129.4c6.04,0,8.78-4.42,8.78-11.53s-2.74-11.36-8.84-11.36-8.73,4.25-8.73,11.36,2.69,11.53,8.78,11.53"/>
    <path class="cls-1" d="M587.38,132.92h-4.03l-.73-3.97h-.22c-1.96,3.13-5.65,4.53-9.51,4.53-7.22,0-10.91-3.3-10.91-10.91v-19.64h4.98v19.3c0,4.81,2.12,7.16,6.66,7.16,6.66,0,8.84-3.86,8.84-10.86v-15.61h4.93"/>
    <path class="cls-1" d="M613.28,102.71l-.62,4.53c-.95-.22-2.24-.39-3.24-.39-4.65,0-8.73,4.03-8.73,10.07v16h-4.92v-29.99h4.03l.56,5.48h.22c1.9-3.3,4.98-6.04,9.18-6.04,1.12,0,2.52.11,3.53.34"/>
    <path class="cls-1" d="M88.11,76.92c-5.99,5.99-8.89,8.84-10.38,16.1-.11.54-.73.54-.73,0v-8.89c0-5.7,5.08-10.92,11.13-16.97l4.87-4.87c6.82-6.82,8.46-14.72,3.04-19.42-.45-.39-.22-.87.38-.6,6.76,3.08,12.09,14.28.5,25.87M76.97,99.78v8.96c0,.54.62.54.73,0,1.5-7.26,4.39-10.17,10.38-16.16l9.49-9.47c11.59-11.59,13.6-22.81,7.7-30.02-.39-.48-.82-.19-.56.39,3.9,8.82-5.45,18.17-9.9,22.62l-6.71,6.71c-6.05,6.05-11.13,11.27-11.13,16.97M51.26,70.97c-11.59,11.59-6.26,22.79.5,25.87.59.27.83-.21.38-.6-5.42-4.7-3.78-12.6,3.04-19.41l19.26-19.27c6.05-6.05,11.13-11.27,11.13-16.97v-8.89c0-.54-.62-.54-.73,0-1.49,7.26-4.39,10.11-10.38,16.1l-23.2,23.18ZM85.6,24.92v-8.96c0-.54-.62-.54-.73,0-1.5,7.26-4.39,10.17-10.38,16.16l-23.88,23.86c-11.59,11.59-13.6,22.81-7.7,30.02.39.48.82.19.56-.39-3.9-8.82,5.45-18.17,9.9-22.62l21.1-21.11c6.05-6.05,11.14-11.27,11.14-16.97M48.09,42.9c-9.97,9.97-11.36,20.51-9.23,27.39.18.59.69.62.68.01-.05-5.78,1.61-10.75,12.16-21.29l22.74-22.73c6.05-6.05,11.13-11.27,11.13-16.97V.41c0-.54-.62-.54-.73,0-1.5,7.26-4.4,10.12-10.39,16.11l-26.37,26.38ZM62.03,115.05c-.2,0-.36-.16-.36-.36v-5.16c0-.2.16-.36.36-.36h8.73c.2,0,.36-.16.36-.36-.02-4.64-3.52-7.79-17.52-5.35l-30.24,5.2S.33,131.67.14,131.9c-.31.37-.07.85.41.81.42-.04,50.31-8.66,50.31-8.66,11.45-1.93,17.7,3.52,19.56,8.62.17.45.7.38.7-.05,0-.31,0-17.21,0-17.21,0-.2-.16-.36-.36-.36h-8.74ZM98.12,104.06l-10,10.01c-6.05,6.05-11.13,11.27-11.13,16.97v1.59c0,.43.54.5.71.05,1.87-5.11,8.11-10.56,19.56-8.62,0,0,49.89,8.62,50.31,8.66.49.04.73-.44.41-.81-.19-.22-23.22-23.25-23.22-23.25l-26.64-4.58ZM109.31,68.81c-.18-.59-.69-.62-.68-.01.05,5.78-1.61,10.74-12.16,21.29l-8.34,8.34c-3.8,3.8-7.21,7.26-9.23,10.73h8.24c.32-.32.64-.64.97-.98l11.97-11.98c9.97-9.97,11.36-20.51,9.23-27.39M76.99,124.3c0,.54.62.54.73,0,.82-4,2.08-6.66,4.03-9.25h-4.4c-.2,0-.36.16-.36.37v8.88ZM140.74,114.34h-1.29v-.67h3.41v.67h-1.34v3.75h-.78v-3.75ZM143.31,113.67h1.12l1.29,3.47,1.29-3.47h1.12v4.42h-.78v-3.41h-.06l-1.23,3.41h-.67l-1.23-3.41h-.06v3.41h-.78v-4.42Z"/>
    <g>
      <path class="cls-1" d="M189.82,177.32v-21.22h11.86v2.35h-9.18v7.52h7.97v2.35h-7.97v9.01h-2.68Z"/>
      <path class="cls-1" d="M204.65,177.32v-21.22h11.86v2.35h-9.18v6.63h7.97v2.32h-7.97v7.58h9.84l-.89,2.35h-11.62ZM215.03,149.56c-.74,1.1-2.85,3.39-4.25,4.46h-1.72v-.36c.86-1.1,2.14-3.06,2.82-4.4h3.15v.3Z"/>
      <path class="cls-1" d="M226.5,177.32h-5.92v-21.22h6.54c6.39,0,10.46,3.6,10.46,10.4,0,7.16-4.1,10.82-11.09,10.82ZM226.74,158.39h-3.48v16.65h2.88c5.74,0,8.62-2.85,8.62-8.44s-2.91-8.2-8.03-8.2Z"/>
      <path class="cls-1" d="M241.7,177.32v-21.22h11.86v2.35h-9.18v6.63h7.97v2.32h-7.97v7.58h9.84l-.89,2.35h-11.62ZM252.07,149.56c-.74,1.1-2.85,3.39-4.25,4.46h-1.72v-.36c.86-1.1,2.14-3.06,2.82-4.4h3.15v.3Z"/>
      <path class="cls-1" d="M271.22,162.13c0,3.39-2.11,4.93-4.25,5.68l5.83,9.51h-3.12l-5.14-8.77h-4.22v8.77h-2.68v-21.22h5.86c5.26,0,7.73,1.99,7.73,6.03ZM260.31,158.42v7.88h3.18c3.45,0,4.96-1.37,4.96-4.04s-1.63-3.83-5.11-3.83h-3.03Z"/>
      <path class="cls-1" d="M286.29,170.75h-8.41l-2.53,6.57h-2.71l8.29-21.31h2.41l8.26,21.31h-2.76l-2.56-6.57ZM283.11,161.96c-.18-.48-.8-2.44-1.01-3.18-.3,1.22-.68,2.5-.92,3.18l-2.41,6.42h6.72l-2.38-6.42Z"/>
      <path class="cls-1" d="M295.89,177.32v-18.88h-6.63l.89-2.35h15.01v2.35h-6.6v18.88h-2.68Z"/>
      <path class="cls-1" d="M306.09,177.32l.89-2.38h1.6v-16.5h-2.5v-2.35h7.67l-.89,2.35h-1.6v16.5h2.5v2.38h-7.67Z"/>
      <path class="cls-1" d="M326.01,177.62c-6.63,0-9.81-4.4-9.81-10.97s3.18-10.88,9.84-10.88,9.75,4.31,9.75,10.91-3.39,10.94-9.78,10.94ZM326.01,175.3c4.82,0,6.96-3.27,6.96-8.62s-2.14-8.56-6.93-8.56-7.01,3.21-7.01,8.56,2.2,8.62,6.98,8.62Z"/>
      <path class="cls-1" d="M353.6,177.32l-11.35-17.63h-.12c.09,1.4.24,3.6.24,5.77v11.86h-2.47v-21.22h3.09l11.33,17.57h.12c-.06-.95-.21-3.66-.21-5.59v-11.98h2.5v21.22h-3.12Z"/>
      <path class="cls-1" d="M374.94,177.32h-5.92v-21.22h6.54c6.39,0,10.46,3.6,10.46,10.4,0,7.16-4.1,10.82-11.09,10.82ZM375.18,158.39h-3.48v16.65h2.88c5.74,0,8.62-2.85,8.62-8.44s-2.91-8.2-8.03-8.2Z"/>
      <path class="cls-1" d="M406.28,169.83c0,4.4-2.65,7.79-8.26,7.79-5.29,0-8.08-3.12-8.08-7.85v-13.67h2.67v13.76c0,3.45,1.84,5.44,5.56,5.44s5.47-2.26,5.47-5.47v-13.73h2.65v13.73Z"/>
      <path class="cls-1" d="M432.11,177.32l-11.35-17.63h-.12c.09,1.4.24,3.6.24,5.77v11.86h-2.47v-21.22h3.09l11.32,17.57h.12c-.06-.95-.21-3.66-.21-5.59v-11.98h2.5v21.22h-3.12Z"/>
      <path class="cls-1" d="M449.15,177.62c-6.63,0-9.81-4.4-9.81-10.97s3.18-10.88,9.84-10.88,9.75,4.31,9.75,10.91-3.39,10.94-9.78,10.94ZM449.15,175.3c4.82,0,6.96-3.27,6.96-8.62s-2.14-8.56-6.93-8.56-7.01,3.21-7.01,8.56,2.2,8.62,6.99,8.62Z"/>
      <path class="cls-1" d="M476.62,162.13c0,3.39-2.11,4.93-4.25,5.68l5.83,9.51h-3.12l-5.14-8.77h-4.22v8.77h-2.67v-21.22h5.86c5.26,0,7.73,1.99,7.73,6.03ZM465.71,158.42v7.88h3.18c3.45,0,4.96-1.37,4.96-4.04s-1.63-3.83-5.11-3.83h-3.03Z"/>
      <path class="cls-1" d="M486.84,177.32h-5.92v-21.22h6.54c6.39,0,10.46,3.6,10.46,10.4,0,7.16-4.1,10.82-11.09,10.82ZM487.08,158.39h-3.48v16.65h2.88c5.74,0,8.62-2.85,8.62-8.44s-2.91-8.2-8.03-8.2Z"/>
      <path class="cls-1" d="M195.73,213h-5.92v-21.22h6.54c6.39,0,10.46,3.6,10.46,10.4,0,7.16-4.1,10.82-11.09,10.82ZM195.97,194.07h-3.48v16.65h2.88c5.74,0,8.62-2.85,8.62-8.44s-2.91-8.2-8.03-8.2Z"/>
      <path class="cls-1" d="M210.93,213v-21.22h11.86v2.35h-9.18v6.63h7.97v2.32h-7.97v7.58h9.84l-.89,2.35h-11.62Z"/>
      <path class="cls-1" d="M234,191.78h2.68v18.84h9.78l-.89,2.38h-11.56v-21.22Z"/>
      <path class="cls-1" d="M259.74,206.43h-8.41l-2.53,6.57h-2.71l8.29-21.31h2.41l8.26,21.31h-2.76l-2.56-6.57ZM256.56,197.63c-.18-.48-.8-2.44-1.01-3.18-.3,1.22-.68,2.5-.92,3.18l-2.41,6.42h6.72l-2.38-6.42Z"/>
      <path class="cls-1" d="M274.52,213v-21.22h11.86v2.35h-9.18v7.52h7.97v2.35h-7.97v9.01h-2.68Z"/>
      <path class="cls-1" d="M302.94,197.81c0,3.39-2.11,4.93-4.25,5.68l5.83,9.51h-3.12l-5.14-8.77h-4.22v8.77h-2.68v-21.22h5.86c5.26,0,7.73,1.99,7.73,6.03ZM292.03,194.1v7.88h3.18c3.45,0,4.96-1.37,4.96-4.04s-1.63-3.83-5.11-3.83h-3.03Z"/>
      <path class="cls-1" d="M318.01,206.43h-8.41l-2.53,6.57h-2.71l8.29-21.31h2.41l8.26,21.31h-2.76l-2.56-6.57ZM314.83,197.63c-.18-.48-.8-2.44-1.01-3.18-.3,1.22-.68,2.5-.92,3.18l-2.41,6.42h6.72l-2.38-6.42Z"/>
      <path class="cls-1" d="M339.36,213l-11.36-17.63h-.12c.09,1.4.24,3.6.24,5.77v11.86h-2.47v-21.22h3.09l11.33,17.57h.12c-.06-.95-.21-3.66-.21-5.59v-11.98h2.5v21.22h-3.12Z"/>
      <path class="cls-1" d="M349.41,202.39c0,5.26,2.44,8.56,7.31,8.56,1.84,0,4.1-.42,5.47-1.07l.77,2.05c-2.38,1.1-3.95,1.37-6.63,1.37-6.51,0-9.75-4.25-9.75-10.94s3.63-10.88,10.2-10.88c2.17,0,4.07.36,5.68,1.16l-.89,2.35c-1.31-.59-2.91-1.16-4.82-1.16-4.55,0-7.34,3.24-7.34,8.56Z"/>
      <path class="cls-1" d="M365.85,213v-21.22h11.86v2.35h-9.18v6.63h7.97v2.32h-7.97v7.58h9.84l-.89,2.35h-11.62Z"/>
    </g>
  </g>
`,
]
